import React from 'react'
import { graphql } from "gatsby"
import * as utils from '../utils'
import Layout from "../components/layout"
import SocialLinks from "../components/sociallinks"

export const pageQuery = graphql`
  query ProductQuery($slug: String!) {
    main: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        nid
        vid
        cover_filepath
        photo_filepath
        image_filepath
        artists
        supportingartists
        official
        soundcloudlink
        facebooklink
        spotifylink
        deezerlink
      }
      headings {
        value
        depth
      }
    }
    allProducts: allMarkdownRemark(filter: { frontmatter: {type: { glob: "*release" }}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            nid
            title
            cover_filepath
            image_filepath
            date
            artists
            supportingartists
            official
          }
        }
      }
    }
  }
`

const ReleaseListComponent = ({title, list}) => (
  list.length > 0 ?
  <div>
    <h3>{title}</h3>
    <div className="covers">
      {list.map(t => {
        let img = utils.getImageURL(t.frontmatter.cover_filepath || t.frontmatter.image_filepath, 300)
        return img ? <span className="cover">
          <a href={'/' + (t.fields && t.fields.slug)}>
            <img src={img} alt={t.frontmatter.title} width="150" height="150" />
          </a>
        </span> : null
      })}
    </div>
    <hr />
  </div> : null
)

const findReleases = (all, myid) => {
  return (all || []).map(t => {
    const rel = (t.frontmatter.artists || '').split(',')
    t._rel = rel
    t._check = myid.toString()
    t._match = rel.indexOf(myid.toString()) !== -1
    return t;
  })
  .filter(t => t._match && t.frontmatter.official === 1)
  .map(t => ({...t, timestamp: Date.parse(t.frontmatter.date)}))
  .sort((a, b) => b.timestamp - a.timestamp)
}

const findFeaturedOn = (all, myid) => {
  return (all || []).map(t => {
    const rel = (t.frontmatter.supportingartists || '').split(',')
    t._rel = rel
    t._check = myid.toString()
    t._match = rel.indexOf(myid.toString()) !== -1
    return t;
  })
  .filter(t => t._match && t.frontmatter.official === 1)
  .map(t => ({...t, timestamp: Date.parse(t.frontmatter.date)}))
  .sort((a, b) => b.timestamp - a.timestamp)
}

const findIsm = (all, myid) => {
  return (all || []).map(t => {
    const rel = (t.frontmatter.artists || '').split(',')
    const rel2 = (t.frontmatter.supportingartists || '').split(',')
    t._match = rel.indexOf(myid.toString()) !== -1 || rel2.indexOf(myid.toString()) !== -1
    return t;
  })
  .filter(t => t._match && t.frontmatter.official !== 1)
  .map(t => ({...t, timestamp: Date.parse(t.frontmatter.date)}))
  .sort((a, b) => b.timestamp - a.timestamp)
}

const ProductTemplate = (props) => {
  // console.log('props', props)
  const {data} = props
  const product = props.pageContext.main
  // let allProducts = data.allProducts.edges.map(t => t.node);
  return <Layout>
    <div className="contentheader">
      <h1>{product.frontmatter.title}</h1>
      <div dangerouslySetInnerHTML={{ __html: product.html || ''}} />
      <p>Pris: <b>{product.frontmatter.price} SEK</b></p>
      <p>För att beställa, skicka ett mail till <a href="mailot:order@on-axis.com">order@on-axis.com</a> med namnet på de produkter du vill köpa, ordrar fullföljs i mån av tid, betalning sker via Swish.</p>
      {
        product.frontmatter.images.map(img => {
          return <img src={utils.getImageURL(img, 1000)} alt={product.frontmatter.title} className="hero" />
        })
      }
    </div>
    {/* {
      (data.main.frontmatter.photo_filepath || data.main.frontmatter.cover_filepath || data.main.frontmatter.image_filepath) ?
      <img src={utils.getImageURL(data.main.frontmatter.photo_filepath || data.main.frontmatter.cover_filepath || data.main.frontmatter.image_filepath, 1000)} alt={data.main.frontmatter.title} className="hero" />
      : null
    }
    <div className="contentwithsidebar">
      <div className="contentwithsidebar-content">
        <div className="mobilepadding">
          <h1>{data.main.frontmatter.title}</h1>
          <SocialLinks frontmatter={data.main.frontmatter} />
          <h3>Biography</h3>
          <div dangerouslySetInnerHTML={{ __html: data.main.html || 'x'}} />
        </div>
      </div>
      <div className="contentwithsidebar-sidebar">
        <ReleaseListComponent title="Releases" list={findReleases(allProducts, data.main.frontmatter.nid)} />
        <ReleaseListComponent title="Featured on" list={findFeaturedOn(allReleases, data.main.frontmatter.nid)} />
        <ReleaseListComponent title="ISM" list={findIsm(allReleases, data.main.frontmatter.nid)} />
      </div>
    </div> */}
  </Layout>
}

export default ProductTemplate
